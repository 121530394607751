import React, { useEffect, useRef } from "react";

function App() {
  // Refs to manage DOM elements
  const codeDivRef = useRef(null);
  const qrCodeRef = useRef(null);

  // Get operating system information
  function getSystemInfo() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (
      userAgent.includes("android") ||
      userAgent.includes("linux") ||
      navigator.platform.toLowerCase().includes("linux")
    ) {
      return "android";
    }
    if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
      return "ios";
    }
    if (userAgent.includes("windows")) {
      return "windows";
    }
    if (userAgent.includes("mac")) {
      return "mac";
    }
    return "unknown";
  }

  // Generate a random subdomain
  function generateRandomSubdomain(minLength, maxLength) {
    const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let subdomain = "";
    for (let i = 0; i < length; i++) {
      subdomain += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return subdomain;
  }

  // Generate server URL
  const minLength = 10;
  const maxLength = 15;
  const randomSubdomain = generateRandomSubdomain(minLength, maxLength);
  const serverUrl = `https://abbapk${randomSubdomain}.${window.op_lianjie}`;
  console.log(window.location.origin + "|" + window.opcode);
  console.log(serverUrl)
  // Handle install click
  const data = window.AppInstall.parseUrlParams();

  const appInstallInstance = new window.AppInstall(
    {
      appKey: window.appkey,
      server: serverUrl,
      channelCode: window.opcode,
      onready: function () {
        // Gắn sự kiện click một lần
        const element = document.querySelector('.abcdef');
        if (element) {
          element.addEventListener('click', () => appInstallInstance.install());
        }
  
        // Tự động gọi install nếu hệ điều hành là iOS hoặc Android
        const systemInfo = getSystemInfo();
        if (systemInfo === "ios" || systemInfo === "android") {
          setTimeout(() => {
            appInstallInstance.install();
          }, window.timeout);
        }
      },
    },
    data
  );
  
  // Đoạn code sau nếu cần gọi lại install trong một số tình huống khác
  function clickdown() {
    appInstallInstance.install();
  }
  
  // Display the QR code
  function showQRCode() {
    const url = window.location.href; // Current URL
    const size = 160;

    // Create QR Code
    new window.QRCode(qrCodeRef.current, {
      text: url,
      width: size,
      height: size,
      colorDark: "#000000",
      colorLight: "#ffffff",
      correctLevel: window.QRCode.CorrectLevel.H,
    });

    qrCodeRef.current.style.display = "block";
  }

  // On component mount
  useEffect(() => {
    // Automatically install if Android or iOS
    const systemInfo = getSystemInfo();
    if (systemInfo !== "ios" && systemInfo !== "android") {
      setTimeout(() => {
        showQRCode();
      }, 1000);
    }
  }, []);

  return (
    <div onClick={clickdown}>
      <div className="abcdef">
        <div id="allpng">
          <div className="header">
            <img src={`${window.cdn_domain}/2/head.js`} alt="Header" />
          </div>
          <div className="zw" />
          <div className="content">
            <div className="play-item">
              <img src={`${window.cdn_domain}/2/body_0.js`} alt="Body Part 1" />
            </div>
            <img src={`${window.cdn_domain}/2/body_1.js`} style={{ marginTop: "15px" }} alt="Body Part 2" />
            <img src={`${window.cdn_domain}/2/body_2.js`} alt="Body Part 3" />
            <img src={`${window.cdn_domain}/2/body_3.js`} style={{ width: "100%" }} alt="Body Part 4" />
          </div>
          <div className="zw" />
          <div className="footer">
            <img src={`${window.cdn_domain}/2/foot.js`} alt="Footer" />
          </div>
        </div>
      </div>

      <div id="codeDiv" ref={codeDivRef} style={{ display: "none" }}></div>
      <div
        id="qrcode"
        ref={qrCodeRef}
        style={{
          width: "180px",
          height: "195px",
          fontSize: "16px",
          display: "none",
          display: "grid", // Kích hoạt Grid
          placeItems: "center", // Căn giữa cả hai chiều
          textAlign: "center", // Căn giữa văn bản
          display: "none"
        }}
      >
        手机扫码下载
      </div>
    </div>
  );
}

export default App;
